export const projects = {
  colective: {
    path: "colective",
    highlight: "m33",
    projects: {
      m33: {
        title: "M33 Residence Vilamoura",
        description: `Tipologia:
        Equipamento Escolar Creche, Jardim de Infância e Primeiro Ciclo educação Sustentável)
        Área total de construção:       1.333 m², num terreno com 50.000 m²
        Valor total do investimento:    2.300.000 EUR
        Número de utentes:              145 alunos dos 2 aos 10 anos
        Fase de projeto:                Aprovado para execução
        Ano:                            2010-2014`,
        images: [
          "27-01-jardim_3 - Foto.jpg",
          "27-01-jardim_1 - Photo.jpg",
          "27-01-jardim_10 - Foto.jpg",
          "27-01-jardim_14 - Foto.jpg",
          "27-01-jardim_15 - Foto.jpg",
          "27-01-jardim_2 - Foto.jpg",
          "27-01-jardim_4 - Foto.jpg",
          "27-01-jardim_5 - Foto.jpg",
          "27-01-jardim_6 - Foto.jpg",
          "27-01-jardim_7 - Foto.jpg",
          "27-01-jardim_8 - Foto.jpg",
          "27-01-jardim_9 - Foto.jpg",
        ],
      },
    },
    images: [
      "m33/27-01-jardim_1 - Photo.jpg",
      "m33/27-01-jardim_10 - Foto.jpg",
      "m33/27-01-jardim_14 - Foto.jpg",
      "m33/27-01-jardim_15 - Foto.jpg",
      "m33/27-01-jardim_2 - Foto.jpg",
      // "m33/27-01-jardim_3 - Foto.jpg",
      "m33/27-01-jardim_4 - Foto.jpg",
      "m33/27-01-jardim_5 - Foto.jpg",
      "m33/27-01-jardim_6 - Foto.jpg",
      "m33/27-01-jardim_7 - Foto.jpg",
      "m33/27-01-jardim_8 - Foto.jpg",
      "m33/27-01-jardim_9 - Foto.jpg",
    ],
  },

  equipments: {
    path: "equipments",
    highlight: "parque_campismo_quarteira",
    projects: {
      creche_quinta_grande: {
        title: "Creche Quinta Grande",
        description: `Tipologia:
        Equipamento Escolar Creche, Jardim de Infância e Primeiro Ciclo educação Sustentável)
        área total de construção:       1.333 m², num terreno com 50.000 m²
        valor total do investimento:    2.300.000 EUR
        número de utentes:              145 alunos dos 2 aos 10 anos
        fase de projeto:                aprovado para execução
        Ano:                            2010-2014`,
        images: [
          "001.jpg",
          "002.jpg",
          "003.jpg",
          "004.jpg",
          "ENQUAD~3_Page_1.jpg",
          "implant.jpg",
          "telasf01.jpg",
          "TELASF~2_Page_1.jpg",
        ],
      },
      estaleiro: {
        title: "Estaleiro Faro",
        description: `Tipologia:
        `,
        images: [
          "005 - PLANO GERAL DE ELEMENTOS - PROPOSTO000011_Page_1.jpeg",
          "016 - SISTEMA DE ESCOAMENTO CANAL_COMPORTA_55_Page_1.jpeg",
          "020 - CORTES E ALÇADOS_60_Page_1.jpeg",
          "1.jpg",
          "10.jpg",
          "11.jpg",
          "12.jpg",
          "13.jpg",
          "14.jpg",
          "15.jpg",
          "16.jpg",
          "17.jpg",
          "2.jpg",
          "21.jpg",
          "22.jpg",
          "3.jpg",
          "4.jpg",
          "5.jpg",
          "6.jpg",
          "7.jpg",
          "8.jpg",
          "9.jpg",
          "CANAL EMISSOR_COLECTOR EXTERNO.png",
          "CANAL EMISSOR_COLECTOR INTERNO .png",
          "EMISSOR_COLECTORES.png",
        ],
      },

      parque_campismo_quarteira: {
        title: "Parque Campismo Quarteira",
        description: `Tipologia: Grande Equipamento - Novo Parque de Campismo de Quarteira
        área total:                             180.500,00 m2
        área total de construção:               10.777,00 m2
        área de Acampamento:            56.036,00 m2
        Espaços Verdes e de Enquadramento:      81.940,21 m2
        valor total do investimento:            19.000.000 EUR
        número total de utentes:                2.350 utentes
        fase de projeto:                        DIA do Projeto de Execução
        Ano: 2018-2022`,
        images: [
          "Renders2_1 - Photo.jpg",
          "004. Planta de Implantação - Ed. Principal, Piscina e Ed. Apoio_109_Page_1.jpeg",
          "03. PLANTA GERAL - PROPOSTO_Page_1.jpeg",
          "Renders2_10 - Foto.jpg",
          "Renders2_14 - Foto.jpg",
          "Renders2_15 - Foto.jpg",
          "Renders2_16 - Foto.jpg",
          "Renders2_2 - Foto.jpg",
          "Renders2_3 - Foto.jpg",
          "Renders2_4 - Foto.jpg",
          "Renders2_5 - Foto.jpg",
          "Renders2_6 - Foto.jpg",
          "Renders2_7 - Foto.jpg",
          "Renders2_9 - Foto.jpg",
        ],
      },
    },
    images: [
      "creche_quinta_grande/001.jpg",
      "creche_quinta_grande/002.jpg",
      "creche_quinta_grande/003.jpg",
      "creche_quinta_grande/004.jpg",
      "creche_quinta_grande/ENQUAD~3_Page_1.png",
      "creche_quinta_grande/implant.jpg",
      "creche_quinta_grande/telasf01.jpg",
      "creche_quinta_grande/TELASF~2_Page_1.png",
      "estaleiro/005 - PLANO GERAL DE ELEMENTOS - PROPOSTO000011_Page_1.jpeg",
      "estaleiro/016 - SISTEMA DE ESCOAMENTO CANAL_COMPORTA_55_Page_1.jpeg",
      "estaleiro/020 - CORTES E ALÇADOS_60_Page_1.jpeg",
      "estaleiro/1.jpg",
      "estaleiro/10.jpg",
      "estaleiro/11.jpg",
      "estaleiro/12.jpg",
      "estaleiro/13.jpg",
      "estaleiro/14.jpg",
      "estaleiro/15.jpg",
      "estaleiro/16.jpg",
      "estaleiro/17.jpg",
      "estaleiro/2.jpg",
      "estaleiro/21.jpg",
      "estaleiro/22.jpg",
      "estaleiro/3.jpg",
      "estaleiro/4.jpg",
      "estaleiro/5.jpg",
      "estaleiro/6.jpg",
      "estaleiro/7.jpg",
      "estaleiro/8.jpg",
      "estaleiro/9.jpg",
      "estaleiro/CANAL EMISSOR_COLECTOR EXTERNO.png",
      "estaleiro/CANAL EMISSOR_COLECTOR INTERNO .png",
      "estaleiro/EMISSOR_COLECTORES.png",
      "parque_campismo_quarteira/004. Planta de Implantação - Ed. Principal, Piscina e Ed. Apoio_109_Page_1.jpeg",
      "parque_campismo_quarteira/03. PLANTA GERAL - PROPOSTO_Page_1.jpeg",
      //"parque_campismo_quarteira/Renders2_1 - Photo.jpg",
      "parque_campismo_quarteira/Renders2_10 - Foto.jpg",
      "parque_campismo_quarteira/Renders2_14 - Foto.jpg",
      "parque_campismo_quarteira/Renders2_15 - Foto.jpg",
      "parque_campismo_quarteira/Renders2_16 - Foto.jpg",
      "parque_campismo_quarteira/Renders2_2 - Foto.jpg",
      "parque_campismo_quarteira/Renders2_3 - Foto.jpg",
      "parque_campismo_quarteira/Renders2_4 - Foto.jpg",
      "parque_campismo_quarteira/Renders2_5 - Foto.jpg",
      "parque_campismo_quarteira/Renders2_6 - Foto.jpg",
      "parque_campismo_quarteira/Renders2_7 - Foto.jpg",
      "parque_campismo_quarteira/Renders2_9 - Foto.jpg",
    ],
  },

  moradias: {
    path: "moradias",
    highlight: "moradias_gambelas",
    projects: {
      alvide: {
        title: "Habitação em alvide",
        description: `Tipologia: habitação unifamiliar.
        área total de construção: 7150 m²
        valor total do investimento: 6737500 EUR
        número total de fogos: 12 unidades
        fase de projeto: aprovado para execução
        Ano: 2007 a 2011`,
        images: [
          "003.bmp",
          "004.bmp",
          "CA45G5SR.jpg",
          "CABII1NZ.jpg",
          "CADWMT1F.jpg",
          "CAO1W5OJ.jpg",
          "CAVEY1JR.jpg",
          "Ideal Line - Alvide - IS_C2 PS.jpg",
          "Idealine_Alvide_Cozinha_FRAME_000.jpg",
          "Idealine_Alvide_Ginasio_0040.jpg",
          "Idealine_Alvide_PlanoGinasio_0200 PS.jpg",
          "Idealine_Alvide_Sala_Cam1_00 PS.jpg",
          "Idealine_Alvide_Sala_Cam2_00 PS.jpg",
          "Idealine_Alvide_Sala_Cam3_00 PS.jpg",
          "localizacao.png",
          "Scan10005.JPG",
        ],
      },

      casa_milhano: {
        title: "Casa Milhano",
        description: `Tipologia: habitação unifamiliar.
        área total de construção: 300 m², num terreno com 12000 m²
        valor total do investimento: 450000 EUR
        número total de fogos: como unidade
        fase de projeto: aprovado para execução
        Ano: 2019-2022`,
        images: [
          "001.jpg",
          "002.jpg",
          "003.jpg",
          "004.jpg",
          "005.jpg",
          "006.jpg",
          "007.jpg",
          "008.jpg",
          "04. PROPOSTO - PLANTAS_17_Page_1.jpeg",
        ],
      },

      casa_sta_barbara: {
        title: "Casa Sta Barbara",
        description: `Tipologia: habitação unifamiliar.
        área total de construção: 300 m²
        
        valor total do investimento: 650000 EUR
        número total de fogos: 2 unidades
        fase de projeto: aprovado para execução
        Ano: 2016`,
        images: ["001.jpg", "cave.png", "piso00.png"],
      },

      moradias_gambelas: {
        title: `Moradias Gambelas`,
        description: `Tipologia: habitação unifamiliar em banda
        área total de construção: 1.650 m² + 1.100 m2
        valor total do investimento: 3.250.000 EUR
        número total de fogos: 11 fogos
        fase de projeto: aprovado para execução
        Ano: 2019-2022`,
        images: [
          "rua.jpg",
          "casa de banho4.jpg",
          "cozinha6.jpg",
          "ginasio&patio.jpg",
          "lougeTelhado.jpg",
          "lougeTelhado3.jpg",
          "quarto.jpg",
          "rua0.jpg",
          "rua1.jpg",
          "rua3.jpg",
        ],
      },

      moradia_mesquita: {
        title: "Moradia Mesquita",
        description: `Tipologia: habitação unifamiliar em banda
        área total de construção: 618 m²m2
        valor total do investimento: 1150000 EUR
        número total de fogos: 1 fogos
        fase de projeto: em aprovação PIP
        Ano: 2021-2022`,
        images: [
          "001 piscina ao final da tarde.jpg",
          "002 piscina ao final da tarde.jpg",
          "003 vista da rua.jpg",
          "004 vista do salão para a piscina.jpg",
          "005 exterior sul birdview.jpg",
          "006 tardoz de manhã.jpg",
          "007 vista da rua nascente.jpg",
        ],
      },
    },
    images: [
      "alvide/003.bmp",
      "alvide/004.bmp",
      "alvide/CA45G5SR.jpg",
      "alvide/CABII1NZ.jpg",
      "alvide/CADWMT1F.jpg",
      "alvide/CAO1W5OJ.jpg",
      "alvide/CAVEY1JR.jpg",
      "alvide/Ideal Line - Alvide - IS_C2 PS.jpg",
      "alvide/Idealine_Alvide_Cozinha_FRAME_000.jpg",
      "alvide/Idealine_Alvide_Ginasio_0040.jpg",
      "alvide/Idealine_Alvide_PlanoGinasio_0200 PS.jpg",
      "alvide/Idealine_Alvide_Sala_Cam1_00 PS.jpg",
      "alvide/Idealine_Alvide_Sala_Cam2_00 PS.jpg",
      "alvide/Idealine_Alvide_Sala_Cam3_00 PS.jpg",
      "alvide/localizacao.png",
      "alvide/Scan10005.JPG",
      "casa_milhano/001.jpg",
      "casa_milhano/002.jpg",
      "casa_milhano/003.jpg",
      "casa_milhano/004.jpg",
      "casa_milhano/005.jpg",
      "casa_milhano/006.jpg",
      "casa_milhano/007.jpg",
      "casa_milhano/008.jpg",
      "casa_milhano/04. PROPOSTO - PLANTAS_17_Page_1.jpeg",
      "casa_sta_barbara/001.jpg",
      "casa_sta_barbara/cave.png",
      "casa_sta_barbara/piso00.png",
      "moradias_gambelas/casa de banho4.jpg",
      "moradias_gambelas/cozinha6.jpg",
      "moradias_gambelas/ginasio&patio.jpg",
      "moradias_gambelas/lougeTelhado.jpg",
      "moradias_gambelas/lougeTelhado3.jpg",
      "moradias_gambelas/quarto.jpg",
      // "moradias_gambelas/rua.jpg",
      "moradias_gambelas/rua0.jpg",
      "moradias_gambelas/rua1.jpg",
      "moradias_gambelas/rua3.jpg",
      "moradia_mesquita/001 piscina ao final da tarde.jpg",
      "moradia_mesquita/002 piscina ao final da tarde.jpg",
      "moradia_mesquita/003 vista da rua.jpg",
      "moradia_mesquita/004 vista do salão para a piscina.jpg",
      "moradia_mesquita/005 exterior sul birdview.jpg",
      "moradia_mesquita/006 tardoz de manhã.jpg",
      "moradia_mesquita/007 vista da rua nascente.jpg",
    ],
  },
};
