import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Link as LinkS } from "react-scroll";
import { motion, AnimatePresence, useAnimation } from "framer-motion";

import { useInView } from "react-intersection-observer";
import { projects } from "../files/projects";
import { FadingCaroussel } from "./Caroussel";
import { i18n } from "../files/languages";
//import m33 from "../images/m33"

const transition = { duration: 0.5, ease: [0.43, 0.13, 0.23, 0.96] };

const thumbnailVariants = {
  initial: { scale: 0.9, opacity: 0 },
  enter: { scale: 1, opacity: 1, transition },
  exit: {
    scale: 0.5,
    opacity: 0,
    transition: { duration: 1.5, ...transition },
  },
};

const frameVariants = {
  hover: { scale: 0.95, transition },
  open: {
    opacity: 1,
    x: 0,
    transition: { duration: 1, ease: "backIn" },
  },
  closed: {
    opacity: 0,
    x: "50%",
    transition: { duration: 1, ease: "backOut" },
  },
};

const imageVariants = {
  hover: { scale: 1.2 },
  open: {
    opacity: 1,
    x: 0,
    transition: { duration: 1, ease: "backIn" },
  },
  closed: {
    opacity: 0,
    x: "50%",
    transition: { duration: 1, ease: "backOut" },
  },
};

const Thumbnail = ({ id, category, main }) => {
  const img = category.projects[id].images[0];

  return (
    <motion.div className="thumbnail" variants={thumbnailVariants}>
      <motion.div
        className="frame"
        whileHover="hover"
        variants={frameVariants}
        transition={transition}
      >
        <LinkS to="work" spy={true} smooth={true} offset={0} duration={700}>
          <Link to={`/project/${category.path}/${id}`}>
            <motion.div
              className="overlay"
              whileHover="hover"
              animate="initial"
              variants={{
                initial: { backgroundColor: "none", opacity: 0 },
                hover: { backgroundColor: "rgb(0,0,0,0.5)", opacity: 1 },
              }}
            >
              <h1 className="overlay-text">{main}⟶</h1>
            </motion.div>
            <motion.img
              src={`/images/${id}/${img}`}
              // src ={}
              alt={main}
              variants={imageVariants}
              transition={transition}
            />
          </Link>
        </LinkS>
      </motion.div>
    </motion.div>
  );
};

const Drawer = (props) => {
  const category = props.category;
  const high = category.highlight;

  return (
    <div className="gallery-drawer">
      <h3 style={{ margin: 0 }}>  {props.main}</h3>
      <Thumbnail id={high} category={category} main={props.main}></Thumbnail>
      <h3 style={{ margin: 0 }}>  {i18n.other_projects}</h3>
      <FadingCaroussel id={high} category={category}></FadingCaroussel>
    </div>
  );
};

const backVariants = {
  exit: { x: 100, opacity: 0, transition },
  enter: { x: 0, opacity: 1, transition: { delay: 1, ...transition } },
};

const galleryVariants = {
  exit: { opacity: 0, transition: { staggerChildren: 0.1, duration: 0.5 } },
  initial: { opacity: 0 },
  enter: {
    opacity: 1,
    transition: { delayChildren: 0.2, staggerChildren: 0.1, duration: 0.5 },
  },
};

export const Gallery = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView();
  useEffect(() => {
    if (inView) {
      controls.start("enter");
    } else {
      controls.start("exit");
    }
  }, [controls, inView]);

  const { colective, equipments, moradias } = projects;
  return (
    <div className="gallery">
      <motion.div
        className="back"
        variants={backVariants}
        initial="exit"
        animate="enter"
        exit="exit"
      >
        <Link to={`/projects/`}>{i18n.all_projects} </Link>
      </motion.div>

      <motion.div
        ref={ref}
        className="thumbnails"
        initial="initial"
        animate={controls}
        exit="exit"
        variants={galleryVariants}
      >
        <h1>COLLECTIVE HOUSING</h1>
        <Drawer category={colective} main="M33 Vilamoura Residence" />
        <h1>EQUIPMENTS</h1>
        <Drawer category={equipments} main="Parque Campismo Quarteira" />
        <h1>HOUSING</h1>
        <Drawer category={moradias} main="Moradias Gambelas" />
      </motion.div>
    </div>
  );
};
