import { Link} from "react-scroll";
import {motion} from "framer-motion";
import { LogoBar } from "../App";
import { LanguageSwitch } from "./LanguageSwitch";
import { i18n } from "../files/languages";


export const NavItem = (props) => (
    <motion.li
      className="nav-item"
      animate={{opacity:1}}
      whileHover={{ scale: 1.05, cursor:" pointer"}}
    >
        {props.children}
    </motion.li>
  )

export const  Navbar = (props) =>{
    return (
      <nav className="navbar">
        <ul className="navbar-nav" id="navbar">
        <LogoBar />
        <NavItem >
          <Link
          activeClass="active"
          to="home"
          spy={true}
          smooth={true}
          offset={0}
          duration={500}
          >
            {i18n.home}
          </Link>  
        </NavItem>
        <NavItem >
          <Link
          activeClass="active"
          to="work"
          spy={true}
          smooth={true}
          offset={0}
          duration={500}
          >
            {i18n.work}
          </Link>  
        </NavItem>
        <NavItem > 
          <Link 
          activeClass="active"
          to="team"
          spy={true}
          smooth={true}
          offset={0}
          duration={500}> 
            {i18n.team}
          </Link>
        </NavItem>
        <NavItem> 
          <Link 
          activeClass="active"
          to="about"
          spy={true}
          smooth={true}
          offset={0}
          duration={500}>
          {i18n.about}
          </Link>
        </NavItem>
        <LanguageSwitch/>
        </ul>
        
      </nav>
    );
  }