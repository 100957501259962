import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { Link as LinkS } from "react-scroll";
import { useEffect, useRef } from "react";

const transition = {
  duration: 1,
  ease: [0.43, 0.13, 0.23, 0.96],
};
const backVariants = {
  exit: { x: 100, opacity: 0, transition },
  enter: { x: 0, opacity: 1, transition: { delay: 0.5, ...transition } },
};

const projectListVariants = {
  exit: { opacity: 0, transition },
  enter: {
    opacity: 1,
    transition: { staggerChildren: 0.1, ...transition },
  },
};

const categoryVariants = {
  exit: { x: -100, opacity: 0, transition },
  enter: {
    x: 0,
    opacity: 1,
    transition: { staggerChildren: 0.1, ...transition },
  },
};

const projectVariants = {
  exit: { x: -100, opacity: 0, transition },
  enter: {
    x: 0,
    opacity: 1,
    transition: { staggerChildren: 0.1, ...transition },
  },
};

const Category = (props) => {
  return (
    <motion.div ref={props.propsRef} id={props.id} variants={categoryVariants}>
      <h1>{props.title}</h1>
      {props.children}
    </motion.div>
  );
};

const Project = (props) => {
  if (!props.link) {
    return <motion.li variants={projectVariants}>{props.children}</motion.li>;
  } else {
    return (
      <LinkS to="work" spy={true} smooth={true} offset={0} duration={700}>
        <Link to={props.link} className="link">
          <motion.li variants={projectVariants}>{props.children}</motion.li>
        </Link>
      </LinkS>
    );
  }
};

const useMountEffect = (fun) => useEffect(fun, []);
export const ProjectList = ({ match }) => {
  const myRef = useRef(null);
  const executeScroll = () => {
    if (myRef.current != null) {
      myRef.current.scrollIntoView();
      window.scrollBy(0, -(window.innerHeight / 5));
    }

  };

  useMountEffect(executeScroll);
  return (
    <>
      <motion.div
        className="back"
        variants={backVariants}
        initial="exit"
        animate="enter"
        exit={"exit"}
      >
        <Link to="/">←Back </Link>
      </motion.div>
      <motion.div
        className="project-list-container"
        variants={projectListVariants}
        initial="exit"
        animate="enter"
        exit="exit"
      >
        <motion.div className="project-list">
          <Category
            propsRef={match.params.id === "colective" ? myRef : null}
            id="colective"
            title="Habitação Coletiva"
          >
            <Project>
              Condomínio de 5 andares, 14 residências, Faro, 1994
            </Project>
            <Project>
              Condomínio de 10 pisos, 40 residências, Quarteira, 1995
            </Project>
            <Project> Condomínio de 6 pisos, 20 residências, Faro</Project>
            <Project>
              Moradias Duplex, 150 residências, Gambelas, Faro, 1997-1998
            </Project>
            <Project>
              Condomínio de 6 pisos, 20 residências, Olhão, 1998
            </Project>
            <Project>
              Condomínios de 4, 5 e 6 pisos, em Faro, Olhão e Quarteira, 1999
            </Project>
            <Project>
              Edifícios de 2, 3, 4, 5 e 6 andares, num total de 160 residências,
              em Olhão, 2000
            </Project>
            <Project>
              Condomínio de 5 pisos, 40 residências, Gambelas, Faro, 2001
            </Project>
            <Project> Condomínio de 14 andares, Faro, 2001</Project>
            <Project> Condomínio de 4 pisos, Faro, 2004</Project>
            <Project> Condomínio de Luxo, 45 unidades, Faro, 2004</Project>
            <Project>
              Moradias Duplex, Condomínio de 16 residências, Gambelas, 2004
            </Project>
            <Project>
              Moradias em Banda Duplex, condomínio de 8 residências, Montenegro,
              2004
            </Project>
            <Project>
              46 Edifícios, 260 residências, Urbanização Desenvolvimento O
              Pomar,
            </Project>
            <Project> Cabanas de Tavira, 2006-2009</Project>
            <Project>
              Moradia em Banda-condomínio de 9 residências, Montenegro, 2004
            </Project>
            <Project>
              Condomínio de Luxo, 45 residências, FARO 2008-2011
            </Project>
            <Project>
              Condomínio em Moncarapacho, 5 duplex, Largo da Igreja, 2016
            </Project>
            <Project> Condomínio Olhão, 2016</Project>
            <Project>
              Condomínio com 12 unidades de Luxo e Piscina, Lote 70, Q.ta do
              Carmo,
            </Project>
            <Project> Tavira 2016</Project>
            <Project>
              242 fogos em 7 Lotes urbanos, Quarteira, 2016-2018
            </Project>
            <Project>
              50 fogos em vários edifícios, O Pomar, Cabanas, 2017-2018
            </Project>
            <Project> 11 Edifícios, 231 fogos, Quarteira, 2017-2023</Project>
            <Project link="/project/colective/m33">
              Residencia M33 Vilamoura
            </Project>
          </Category>
          <Category id="" title="Empreendimentos Turísticos">
            <Project> Albergaria***, 25 quartos, SaProjectr, 1995</Project>
            <Project> Pousada ****, Mértola, 1996</Project>
            <Project> Hotel rural, Loulé, 1997</Project>
            <Project> Hotel *****, 250 quartos, Lagos, 2001</Project>
            <Project> Hotel ****, 220 quartos, Tavira, 2005</Project>
            <Project> Aparthotel ****, 92 residências, Tavira, 2004</Project>
            <Project>
              Hotel *****, 200 quartos, estudo preProjectminar, no complexo
              turístico da
            </Project>
            <Project> Quinta da Ombria, 2009</Project>
            <Project> Casa Farias, Querença, 2015</Project>
            <Project>
              Estudos Parciais de AcessibiProjectdade - Club Med, Olhos d'Agua,
              2016
            </Project>
            <Project>Mercedes Country House, Medronhal, Faro 2016-2018</Project>
            <Project> Instalações Turísticas</Project>
            <Project>
              Macro-Lote 44, 85 residências, Qta. Boavista, Lagos, 2000
            </Project>
            <Project> Clubhouse, Quinta da Boavista, Lagos, 2000</Project>
            <Project>
              Macro-Lote 62, 92 residências, Qta. Boavista, Lagos, 2001
            </Project>
            <Project> Restaurante, Qt. Boavista, Lagos, 2001</Project>
            <Project>
              Complexo Turístico Termas dos Cucos (estudo anterior), Torres
              Vedras,
            </Project>
            <Project> 2002-2003</Project>
            <Project>
              Complexo Turístico de O Pomar (260 resid.), Cabanas de Tavira,
              2004-2006
            </Project>
            <Project>
              Clubhouse e Duas Aldeias Turísticas, Estudo PreProjectminar,
              Complexo Turístico Quinta da Ombria, 2009
            </Project>
            <Project> Restaurante "A TertúProjecta", Faro, 2012-2013</Project>
            <Project> Restaurante Pizza de Pedra (remodelação), 2014</Project>
          </Category>
          <Category id="" title="Turismo Rural">
            <Project>
              Casa Colaço, Aldeia Turística, Sta. Cruz, Almodôvar, 1992
            </Project>
            <Project>
              Casas Lampreia; Aldeia Turística, Mesquita, Mértola, 1992
            </Project>
            <Project>
              Casa AbdúProjecto, Aldeia de Turismo e Casa de Snacks Típicos,
              Odeleite, 1993
            </Project>
            <Project> Casa Projectma, Agroturismo, Paderne, 1994</Project>
            <Project>
              Casa Farrajota, Agroturismo, BoProjectqueime, 1995
            </Project>
            <Project> Quinta Projectma, Agroturismo, Paderne, 1995</Project>
            <Project> Casa Vicente, Agroturismo, Querença, 1997</Project>
            <Project> Casa Farias, Querença, 2015</Project>
            <Project>Mercedes Country House, Medronhal, Faro 2016-2018</Project>
            <Project>
              Quinta das SaProjectnas, Bela Curral, Olhão (Ria Formosa),
              Habitação e Turismo da Natureza, 2015-2017
            </Project>
            <Project>
              Quinta da Garganta, Salgados, Faro (Ria Formosa), Turismo da
              Natureza,
            </Project>
            <Project> 2018</Project>
            <Project>
              Casas Milagros, Turismo da Natureza, Sítio do Pinheiro, Tavira,
              2018
            </Project>
          </Category>
          <Category
            propsRef={match.params.id === "equipments" ? myRef : null}
            id="equipments"
            title="Equipamentos"
          >
            <Project> Centro Comunitário, Barranco do Velho, 1992-1997</Project>
            <Project> Centro de Animação Infantil, Odeleite, 1994</Project>
            <Project> Eco-Museum, Odeleite 1994</Project>
            <Project> Lar da Terceira Idade, Querença, 1997</Project>
            <Project>
              Expansão do Hospital Privado em Faro. Expansão dos Enfermaria do
            </Project>
            <Project>
              Hospital de S. Maria, incluindo a Urgência, o Bloco Operatório,
              Recobro, e
            </Project>
            <Project> Ambulatório, Faro, 1996-1998</Project>
            <Project> Jardim de Infância, Faro, 2004</Project>
            <Project>
              Hospício de 60 quartos, estudo preProjectminar, Faro, 2004
            </Project>
            <Project> Jardim de Infância, Montenegro (Faro), 2004</Project>
            <Project> Lar da terceira idade, 120 quartos, Faro 2010</Project>
            <Project link="/project/equipments/creche_quinta_grande">
              Creche, Jardim de Infância, Escola Primária e Quinta de
              Aprendizagem, Quinta Grande, Galvana, Faro, 2010-2012
            </Project>
            <Project>
              Grande Unidade de Residência Assistida, 250 residências,
              Quarteira,
            </Project>
            <Project> desde 2010.</Project>
            <Project> Clínicas Médicas em Faro, Loulé e Tavira, 2008;</Project>
            <Project link="/project/equipments/estaleiro">
              Coordenação Geral do Projeto, da Equipa de AIA AmpProjectação e do
              Projeto de Projectcenciamento e Execução da AmpProjectação e
              Modernização do Estaleiro Naval da Quinta do Progresso, 2014-2018,
              com a criação de Marina para 180 veleiros;
            </Project>
            <Project link="/project/equipments/parque_campismo_quarteira">
              Coordenação Geral do Projeto, da Equipa de AIA AmpProjectação e do
              Projeto de Projectcenciamento e Execução do Novo Parque de
              Campismo e Caravanismo de Quarteira (18 ha, 2350 utentes, vários
              equipamentos);2017-2022.
            </Project>
          </Category>
          <Category id="" title="Urbanismo e planeamento urbano">
            <Project>Urbanização, 15 residências, Paredes, Porto, 1994</Project>
            <Project>
              Plano de Pormenor de Portal do Sol, 850 hectares, Estudos
              PreProjectminares e Caracterização, 1994
            </Project>
            <Project>
              Urbanização, 25 residências, Perre, Viana do Castelo, 1995
            </Project>
            <Project>
              Urbanização, 265 residências, Gambelas, Faro, 2006-2010.
            </Project>
            <Project>
              Urbanização, 55 residências, Gambelas, Faro, 2000-2011
            </Project>
            <Project> Urbanização, 160 residências, Olhão, 2000-2001.</Project>
            <Project>
              Plano Pormenor do Desenvolvimento da Horta do Espanha, Olhão,
              1997-1999
            </Project>
            <Project>
              Plano de Pormenor de Desenvolvimento Qtª das Âncoras, Olhão,
              1997-2001
            </Project>
            <Project>
              Plano Pormenor do Desenvolvimento de São Luis, Faro, 1996-2003
            </Project>
            <Project>
              Urbanização Quinta do Sobreiro, Gambelas, 250 residências,
              2003-2009
            </Project>
            <Project> Urbanização 40 residências, Montenegro, 2004</Project>
            <Project> Urbanização 60 residências, Pera, 2004</Project>
            <Project> Urbanização 5 moradias, Pera, 2004</Project>
            <Project>
              Urbanização 8 moradias, Montenegro, Faro 2005-2006
            </Project>
            <Project>
              Urbanização 24 residências, Montenegro, Faro 2005-2006
            </Project>
            <Project> Urbanização 12 moradias, Alvide, Cascais 2006</Project>
            <Project> Urbanização 50 residências, Faro 2006</Project>
            <Project> Urbanização 250 residências, Tavira 2006-2009</Project>
            <Project>
              Urbanização 44 residências, Santa Bárbara de Nexe 2008-2009
            </Project>
            <Project>Urbanização 231 residências - Quarteira 2007-2010</Project>
            <Project>
              Alteração à Projectcença de Alvará de Loteamento 3/90, Alto do
              Forte Novo (249 fogos), CM de Loulé: em apreciação.2017
            </Project>
            <Project>
              Estudo de Alteração do PPSMVPM, por desanexação da Parcela 53,
              2015
            </Project>
            <Project>
              Alteração à Projectcença de Alvará de Loteamento 5/2006, O Pomar,
              Cabanas, Tavira (268 fogos), CM de Tavira: aprovado.2017
            </Project>
            <Project>
              Alteração SimpProjectficada ao Alvará de Loteamento 3/90, Quinta
              do Carmo, Tavira (436 fogos): Aprovada.2018
            </Project>
            <Project>
              Alteração SimpProjectficada ao Alvará de Loteamento 2/90, Horta
              das Figuras, Faro: em estudo.2018
            </Project>
            <Project>
              Loteamento Urbano, 60.000,00m2, 125 fogos, PPQN, Quarteira, em
              estudo
            </Project>
          </Category>
          <Category
            propsRef={match.params.id === "moradias" ? myRef : null}
            title="Moradias"
          >
            <Project> Villa Laranjo, 480m2, Stª Bárbara, Faro, 1993</Project>
            <Project> Villa Ramôa, 320m2, Stª Bárbara, 1993</Project>
            <Project> Villa Pescada, 580m2, Albufeira, 1994</Project>
            <Project> Villa Brito, 360m2, Olhão, 1995</Project>
            <Project>
              Moradias geminadas, T2, T3 e T4, construção evolutiva 80
              residências, 1996
            </Project>
            <Project> Villa Cintra, 320m2, Estoi, 1997</Project>
            <Project> 2moradias em Perre, Viana do Castelo, 1997</Project>
            <Project>
              Constantino Jordan Villa, 550m2, Qtª do Lago, 2001
            </Project>
            <Project> Villas, T3 e T4, 25 uni., Gambelas, 2001</Project>
            <Project> Villa Nabais, Tavira, 2005</Project>
            <Project link="/project/moradias/alvide">
              12 moradias, Alvide, Cascais 2006
            </Project>
            <Project> Villa em Santa Bárbara de Nexe, Faro, 2006</Project>
            <Project link="/project/moradias/casa_sta_barbara">
              {" "}
              Moradia, Sta. Barbara de Nexe, 2016
            </Project>
            <Project> Casa Guido e Nitza, Quelfes, 2017-18</Project>
            <Project link="/project/moradias/casa_milhano">
              Casa Milhano, Fuzeta, 2017-18
            </Project>
            <Project link="/project/moradias/moradias_gambelas">
              {" "}
              11Moradias em banda em Gambelas, 2018
            </Project>
            <Project> 2 Moradias isoladas Gambelas. 2018</Project>
            <Project link="/project/moradias/moradia_mesquita">
              {" "}
              1 moradia em Mesquita, Tor, 2022
            </Project>
          </Category>
          <Category id="" title="Arquitetura De Interiores">
            <Project>
              * Boutique de produtos regionais Casa da Serra 1, SaProjectr, 1992
            </Project>
            <Project>
              * Boutique de produtos regionais Casa da Serra 2, Loulé, 1993
            </Project>
            <Project>
              * Boutique de produtos regionais Casa da Serra 3, Faro, 1994
            </Project>
            <Project> Cervejaria Bock, Faro, 1995</Project>
            <Project> Clínica Médica StªMaria, Ambulatório, Faro, 1996</Project>
            <Project> Bar Reitoria, Faro, 1997</Project>
            <Project> Loja NUNO GAMA, Marina de Vilamoura, 1997</Project>
            <Project> Loja Legere, Quinta do Lago, 1998</Project>
            <Project> Loja Peças Soltas, Faro, 1998</Project>
            <Project> Escritórios TMN, Departamento Técnico, 1998</Project>
            <Project>
              Várias clínicas dentárias, em Faro, Olhão e Tavira, 1998
            </Project>
            <Project> Loja Mascate, bijuteria, Faro, 1998</Project>
            <Project> Columbus Bar, Faro, 1998</Project>
            <Project> Restaurante Costa Algarvia, Faro, 1998</Project>
            <Project> Loja Legere, Quinta Shopping, Qtª do Lago, 1999</Project>
            <Project>
              * Centro Veterinário de BoProjectqueime; BoProjectqueime, 1999
            </Project>
            <Project> Hospital Veterinário do Algarve, Faro, 1999</Project>
            <Project> * Bar Património, Faro, 1999-2001</Project>
            <Project>
              Renovação de 25 postos de turismo da região turística do Algarve,
            </Project>
            <Project>
              incluindo a criação manual da nova marca para os escritórios de
              turismo
            </Project>
            <Project> RTA, em funcionamento desde Janeiro de 2001.</Project>
            <Project>
              Residência Beloura, Design de Interiores e Espaços Exteriores,
              Quinta da
            </Project>
            <Project> Beloura, Cascais, 2005</Project>
            <Project> Casas da Falésia, Ericeira, 2008-2009</Project>
          </Category>
        </motion.div>
      </motion.div>
    </>
  );
};

export default ProjectList;
