const languages = {
  en: {
    home: "HOME",
    work: "WORK",
    team: "TEAM",
    about: "ABOUT",
    team_roles: {
      tierri: "Chief Architect",
      ines: "Senior Architect",
      ana: "Intern Architect",
      goncalo: "3D Artist/ IT Suppervisor",
      siza: "Team Emotional Terapist",
      carla: "Comercial Director",
    },
    location: "Location",
    location_text:
      " Tierri Farias Arquitectos studio, is located in one of Faro main avenues Avenida 5 de Outubro, right next  to Escola Secundária João de Deus.  ",
    mission: "Mission",
    mission_text: "",
    about_us: "About us",
    about_us_text: `Planning and Architectural corporation work with national and international investors.
    Diversified Projects in Hospitality, Senior Housing, Housing, Resorts, Health facilities, Sports facilities, Education facilities and Interior Design for corporate and commercial clients.
    Important work on consulting in urban planning, real state evaluation, sustainability and environmental strategy for legal adviser, investors and development institutions.`,
    other_projects: "Other Projects",
    all_projects: "All Projects⟶",
  },

  pt: {
    home: "INÍCIO",
    work: "TRABALHO",
    team: "EQUIPA",
    about: "SOBRE",
    team_roles: {
      tierri: "Arquitecto Chefe",
      ines: "Arquitecta Senior",
      ana: "Arquitecta Estagiária",
      goncalo: "Artista 3D/ Supervisor IT ",
      siza: "Terapeuta Emocional de Equipa",
      carla: "Diretora Comercial",
    },
    location: "Localização",
    location_text:
      "O gabinet da Tierri Farias Arquitectos, está localizado numa das principais avenidas de Faro, a Avenida 5 de Outubro, mesmo em seguida da Escola Secundária João de Deus. ",
    mission: "Missão",
    mission_text: "frase teste",
    about_us: "Sobre nós",
    about_us_text: `Planning and Architectural corporation work with national and international investors.
    Diversified Projects in Hospitality, Senior Housing, Housing, Resorts, Health facilities, Sports facilities, Education facilities and Interior Design for corporate and commercial clients.
    Important work on consulting in urban planning, real state evaluation, sustainability and environmental strategy for legal adviser, investors and development institutions.`,
    other_projects: "Outros Projetos",
    all_projects: "Todos os Projetos⟶",
  },
};

var language = "en";
if (localStorage.getItem("lang")) language = localStorage.getItem("lang");

const languagePick = () => {
  if (language === "en") {
    return languages.en;
  } else if (language === "pt") return languages.pt;
};

if (language === "pt") {
  document.documentElement.style.setProperty("--nav-font-mobile", "5.5em");
} else {
  document.documentElement.style.setProperty("--nav-font-mobile", "7em");
}

export const i18n = languagePick();
