import { useEffect } from "react";
import { motion, useAnimation, AnimatePresence } from "framer-motion";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Link } from "react-scroll";
import { Gallery } from "./components/Gallery";
import { SingleImage } from "./components/SingleImage";
import { Navbar } from "./components/Navbar";
//import {MyComponent} from './components/Maps'
import { useInView } from "react-intersection-observer";
import logo from "./images/LOGO_PRETO2.png";
import logoTran from "./images/LOGO_TRANS.png";
import ines from "./images/INES2.jpg";
import tierri from "./images/TIERRI.jpg";
import carla from "./images/CARLA.jpg";
import siza from "./images/SIZA.jpg";
import goncalo from "./images/GONCALO.jpg";
import ana from "./images/ANA.jpg";
import maleAvatar from "./images/avatar-2.svg";
import avatar from "./images/avatar-1.svg";
import { BsPhone } from "react-icons/bs";
import { FiMail } from "react-icons/fi";
import { ImLocation } from "react-icons/im";
import { i18n } from "./files/languages";
// import locationImage from "./images/localização.jpg"
import { ProjectList } from "./components/ProjectList";
import { Contact } from "./components/Contact";

function App() {
  return (
    <>
      <Home id="home" />
      <Work id="work" />
      <Team id="team" />
      <About id="about" />
    </>
  );
}

const LoadingScreen = () => {
  const loading = {
    initial: {
      opacity: 1,
    },
    exit: {
      visibility: "hidden",
      opacity: 0,
      transition: {
        opacity: { delay: 3, type: "spring", bounce: 0 },
        visibility: {
          delay: 4,
        },
      },
    },
  };

  const draw = {
    hidden: { pathLength: 0, opacity: 0 },
    visible: (i) => {
      const delay = 1 + i * 0.5;
      return {
        pathLength: 1,
        opacity: 1,
        transition: {
          pathLength: { delay, type: "spring", duration: 2.5, bounce: 0 },
          opacity: { delay, duration: 0.01 },
        },
      };
    },
  };

  return (
    <>
      <motion.div
        variants={loading}
        initial="initial"
        animate="exit"
        className="loading"
      >
        <motion.svg
          width="250"
          height="250"
          viewBox="0 0 96 103"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          initial="hidden"
          animate="visible"
          className="svg"
        >
          <motion.path
            variants={draw}
            d="M22 94H88"
            stroke="white"
            style={{ strokeWidth: 12 }}
          />
          <motion.path
            variants={draw}
            d="M6 77C6 37 35.5 6 85.5 6M24.5 77C24.5 37 62.5 25.5 76.5 25.5"
            stroke="white"
          />
          <motion.line
            variants={draw}
            x1="24.5"
            y1="100.019"
            x2="24.5"
            y2="74"
            stroke="white"
          />
        </motion.svg>
        <motion.div className="text-loading">
          <p>Tierri Farias </p>
          Arquitectos
        </motion.div>
      </motion.div>
    </>
  );
};

export const LogoBar = () => {
  return (
    <>
      <div
        className="logo-bar" /*
            style={{
              width: width,
              opacity:opacity
            }}
          */
      >
        <div className="contact-logo">
          <a className="contact-button" href="tel:+351961621111">
            <BsPhone />
          </a>
          <a className="contact-button" href="mailto:geral@tfarquitectos.pt">
            <FiMail />
          </a>
          <a
            className="contact-button"
            href="https://www.google.com/maps/place/TIERRI+FARIAS+ARQUITECTOS,+Av.+5+de+Outubro+55+2%C2%BA+DIREITO,+8000-075+Faro/@37.0183152,-7.9249229,17z/data=!4m2!3m1!1s0xd0552d72fb7c715:0x9139c05c1e2c501"
          >
            <ImLocation />
          </a>
        </div>

        <Link
          activeClass="active"
          to="home"
          spy={true}
          smooth={true}
          offset={-100}
          duration={500}
        >
          <img src={logo} alt="Logo" className="logo"></img>
        </Link>
      </div>
    </>
  );
};

export const Home = (props) => {
  return (
    <div className="home-card" id={props.id}>
      <LoadingScreen />
      <div className="watermark">
        {/* <div className="text-loading-2">
          <p>Tierri Farias </p>
          Arquitectos
        </div> */}
        <img src={logoTran} alt="Logo" width="250"></img>
      </div>
      <Navbar />
    </div>
  );
};

const Work = (props) => {
  return (
    <div id={props.id} className="work-card">
      <h1 className="mobile-nav-marker light">{i18n.work}</h1>
      <Router>
        <Route
          render={({ location }) => (
            <AnimatePresence exitBeforeEnter initial={false}>
              <Switch location={location} key={location.pathname}>
                <Route exact path="/" component={Gallery} />
                <Route
                  exact
                  path="/project/:category/:id"
                  component={SingleImage}
                />
                <Route exact path="/projects/:id" component={ProjectList} />
                <Route exact path="/projects" component={ProjectList} />
              </Switch>
            </AnimatePresence>
          )}
        />
      </Router>
    </div>
  );
};

var counter = 0;

const TeamCard = (props) => {
  const item = {
    hidden: { y: 100, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };
  /*{`https://picsum.photos/300/300?random = ${props.id}&grayscale`} */
  return (
    <motion.div className="card" variants={item}>
      <motion.img
        /*whileHover={{ scale: 1.1, transition: { duration: 0.6 }, filter: "blur(2px)" }}*/
        src={props.image}
      ></motion.img>
      <motion.div
        /*whileHover={{ opacity: 1, transition: { duration: 0.8 } }}*/
        className="overlay"
      >
        <h2> {props.name}</h2>
        {props.job}
      </motion.div>
    </motion.div>
  );
};

const Team = (props) => {
  const container = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.7,
        staggerChildren: 0.2,
      },
    },
  };

  const controls = useAnimation();
  const [ref, inView] = useInView();
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  return (
    <motion.div className="team-card" id={props.id}>
      <h1 className="mobile-nav-marker dark">{i18n.team}</h1>

      <motion.div
        className="team-grid"
        variants={container}
        initial="hidden"
        ref={ref}
        animate={controls}
      >
        <TeamCard
          id={counter++}
          name="Tierri Farias"
          job={i18n.team_roles.tierri}
          image={tierri}
        ></TeamCard>
        <TeamCard
          id={counter++}
          name="Inês Barroso"
          job={i18n.team_roles.ines}
          image={ines}
        ></TeamCard>
        <TeamCard
          id={counter++}
          name="Ana Cavaco"
          job={i18n.team_roles.ana}
          image={ana}
        ></TeamCard>
        <TeamCard
          id={counter++}
          name="Carla Pinto"
          job={i18n.team_roles.carla}
          image={carla}
        ></TeamCard>
        <TeamCard
          id={counter++}
          name="Gonçalo Farias"
          job={i18n.team_roles.goncalo}
          image={goncalo}
        ></TeamCard>
        <TeamCard
          id={counter++}
          name="Siza"
          job={i18n.team_roles.siza}
          image={siza}
        ></TeamCard>
        <TeamCard
          id={counter++}
          name="Looking for"
          job="Arquitect (m/f)"
          image={avatar}
        ></TeamCard>
      </motion.div>
    </motion.div>
  );
};

const About = (props) => {
  const mapVariants = {
    initial: {
      opacity: 0,
      scale: 0.5,
      x: 1000,
    },
    enter: {
      opacity: 1,
      scale: 1,
      x: 0,
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <div className="about-card" id={props.id}>
      <h1 className="mobile-nav-marker light">{i18n.about}</h1>
      <div className="about-container">
        <div className="about-text">
          <h1>{i18n.about_us}</h1>
          <p> {i18n.about_us_text}</p>
        </div>
        <div className="about-graphics"></div>
      </div>
      <div className="about-container">
        <div className="about-graphics"></div>
        <div className="about-text">
          <h1>{i18n.mission}</h1>
          <p> {i18n.mission_text}</p>
        </div>
      </div>
      <div className="about-container">
        <div className="about-text">
          <h1>{i18n.location}</h1>
          <p> {i18n.location_text}</p>
        </div>
        <div className="about-graphics">
          {/* <img
          src = {locationImage}>
          </img> */}
          <motion.div
            className="map-container"
            variants={mapVariants}
            initial="initial"
            animate="enter"
          >
            {/* <iframe
              // title="map"
              // src="https://snazzymaps.com/embed/345730"
              // style={{ height: "100%", width: "100%" }}

              style={{ height: "100%", width: "100%" }}
              src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBsDElzjj67bV6Yqg0mc7jMS3xNu8NeRWk&q=Eiffel+Tower,Paris+France"
            ></iframe> */}

            <iframe
              style={{ height: "100%", width: "100%" }}
              src={"https://www.google.com/maps/embed/v1/place?q=place_id:ChIJFce3L9dSBQ0RAcXiwQWcEwk&key=" + process.env.REACT_APP_GOOGLE_API_KEY}
            ></iframe>
          </motion.div>
        </div>
      </div>

      <Contact />
    </div>
  );
};

function getOffset(el) {
  const rect = el.getBoundingClientRect();
  return {
    left: rect.left + window.scrollX,
    top: rect.top + window.scrollY,
  };
}

// var prevScrollpos = window.pageYOffset;

document.onscroll = function () {
  var work = 0;
  var team = 0;
  var about = 0;
  if (document.getElementById("work")) {
    work = getOffset(document.getElementById("work")).top;
    //console.log(rect.top);
  }
  if (document.getElementById("team")) {
    team = getOffset(document.getElementById("team")).top;
  }
  if (document.getElementById("about")) {
    about = getOffset(document.getElementById("about")).top;
  }
  if (
    (window.pageYOffset > work - 150 && window.pageYOffset < team - 50) ||
    window.pageYOffset > about - 50
  ) {
    document.documentElement.style.setProperty("--text-nav", "#ffffff");
  } else if (window.pageYOffset > team - 250) {
    document.documentElement.style.setProperty("--text-nav", "#151616");
  } else {
    document.documentElement.style.setProperty("--text-nav", "#151616");
  }
};

export default App;
