import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { projects } from "../files/projects.js";
import { FadingCarousselWithArrows } from "./Caroussel.jsx";
import { i18n } from "../files/languages";

const transition = {
  duration: 1,
  ease: [0.43, 0.13, 0.23, 0.96],
};

const imageVariants = {
  exit: { y: "50%", opacity: 0, transition },
  enter: {
    y: "0%",
    opacity: 1,
    transition,
  },
};

const backVariants = {
  exit: { x: 100, opacity: 0, transition },
  enter: { x: 0, opacity: 1, transition: { delay: 1, ...transition } },
};

const textVariants = {
  exit: { opacity: 0, transition },
  enter: { opacity: 1, transition: { delay: 1, ...transition } },
};

export const SingleImage = ({ match }) => {
  var id,
    category,
    projectsFromCat,
    project,
    imgs,
    description0,
    description = [];
  if (match.params) {
    id = match.params.id;
    category = match.params.category;
    projectsFromCat = projects[category];
    project = projectsFromCat.projects[id];
    imgs = project.images;
    description0 = project.description;
  }

  description0.split(/(?:\r?\n)+/).forEach((element) => {
    description.push(element.trim());
  });

  return (
    <>
      <motion.div
        className="back"
        variants={backVariants}
        initial="exit"
        animate="enter"
        exit="exit"
      >
        <Link to="/">←Back </Link>
        <Link to={`/projects/${category}`}>{i18n.all_projects} </Link>
      </motion.div>
      <motion.div className="single" initial="exit" animate="enter" exit="exit">
        <motion.div className="image-row" variants={imageVariants}>
          <motion.img src={`/images/${id}/${imgs[0]}`} alt={match.params.id} />
          <div className="caroussel">
            <FadingCarousselWithArrows imgs={imgs} id={id} />
          </div>
        </motion.div>

        <div className="flex-container">
          <motion.div className="project-description" variants={textVariants}>
            <h2>{project.title}</h2>
            {description.map((des, i) => (
              <h5 key={i}>{des}</h5>
            ))}
          </motion.div>
        </div>
      </motion.div>
    </>
  );
};
